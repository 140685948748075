import React from 'react';
import { reducer, initialState } from './reducer';

export const UserContext = React.createContext({
  state: initialState,
  dispatch: () => null,
});

export const UserProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <>
      <UserContext.Provider value={[state, dispatch]}>
        {children}
      </UserContext.Provider>
    </>
  );
};

export let IsDark = () => {
  const [state, dispatch] = React.useContext(UserContext);
  return state.isDarkTheme;
};
